import { NotificationVariant } from '@nsftx/seven-components'
import { useWorkingTimeStore } from '@nsftx/seven-client-retail-vue-dm'
import { useNotificationsStore } from '@/stores/notifications/notificationsStore'
import i18n from '@/plugins/translationsPlugin'

let timeoutId: number
const startWorkingTimeValidation = () => {
  const workingTimeStore = useWorkingTimeStore()
  const notificationsStore = useNotificationsStore()
  const { t } = i18n.global
  const { isValid, reason = '', scheduleTime } = workingTimeStore.isWorkingTimeValid()

  notificationsStore.closeNotificationById('working_time_error')

  /**
   * TODO: Emit onBettingDenied/onBettingAllowed based on
   * working time validity
   */
  if (!isValid) {
    notificationsStore.showNotification({
      id: 'working_time_error',
      messages: [t(reason)],
      variant: NotificationVariant.Error
    })
  }

  if (timeoutId) {
    clearTimeout(timeoutId)
  }
  timeoutId = window.setTimeout(startWorkingTimeValidation, scheduleTime)
}

const initWorkingTimeService = () => {
  startWorkingTimeValidation()
}

export { initWorkingTimeService }
