<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useIdleWarningModalStore } from '@/stores/idleWarningModal/idleWarningModalStore'

const INACTIVITY_TIME = 5
const time = ref(INACTIVITY_TIME)
let timerId: number | null = null

const idleWarningModalStore = useIdleWarningModalStore()
const { showIdleWarningModal } = storeToRefs(idleWarningModalStore)

const startInterval = () => {
  timerId = window.setInterval(() => {
    time.value -= 1
    if (time.value < 0 && timerId) {
      clearInterval(timerId)
      idleWarningModalStore.setShowWarningModal(false)
      // Reset timer value
      setTimeout(() => {
        time.value = INACTIVITY_TIME
      }, 200)
    }
  }, 1000)
}

const init = () => {
  idleWarningModalStore.setShowWarningModal(false)
}

const classes = computed(() => ({ flash: time.value <= 1 }))
const displayTime = computed(() => (time.value <= 0 ? 1 : time.value))

watch(showIdleWarningModal, (value) => {
  if (value) startInterval()
})

onMounted(() => {
  init()
})
onUnmounted(() => {
  if (timerId) {
    clearInterval(timerId)
    idleWarningModalStore.setShowWarningModal(false)
  }
})
</script>

<template>
  <!-- touchstart.stop will prevent closing modals in backgound -->
  <div v-if="showIdleWarningModal" id="t-idle-notification-modal" @touchstart.stop="() => {}">
    <div class="content-wrapper" data-cy="idleTimerModal">
      <h1 class="idle-modal-title">
        {{ $t('inactivity_modal_title') }}
      </h1>
      <h2 class="idle-modal-description">{{ $t('inactivity_modal_description') }}:</h2>
      <div>
        <div class="animation-wrapper">
          <div class="circle-wrapper">
            <div class="circle" :class="classes">
              <div class="wrapper" :class="classes">
                <span class="text">
                  {{ displayTime }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 class="idle-modal-info">
        {{ $t('inactivity_modal_info') }}
      </h2>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#t-idle-notification-modal {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  z-index: calc(var(--sc-zindex-keyboard) + 1);
  background: rgba(0, 0, 0, 0.75);
}

.wrapper {
  --text-color: #fff;
  --color-primary-darker: var(--sc-main-primary);
  width: 240px;
  height: 240px;
  border-radius: 50%;
  border: 40px solid transparent;
  background-size:
    100% 100%,
    50% 50%,
    50% 50%,
    50% 50%,
    50% 50%;
  background-repeat: no-repeat;
  background-image:
    linear-gradient(var(--sc-main-primary), var(--sc-main-primary)),
    linear-gradient(45deg, var(--color-primary-darker), 50%, var(--sc-main-primary) 50%),
    linear-gradient(135deg, var(--color-primary-darker), 50%, var(--sc-main-primary) 50%),
    linear-gradient(315deg, var(--color-primary-darker), 50%, var(--sc-main-primary) 50%),
    linear-gradient(225deg, var(--color-primary-darker), 50%, var(--sc-main-primary) 50%);
  background-position:
    center center,
    left top,
    right top,
    left bottom,
    right bottom;
  background-origin: content-box, border-box, border-box, border-box, border-box;
  background-clip: content-box, border-box, border-box, border-box, border-box;
  transform: rotate(30deg);
  animation: scale 1.75s infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  font-weight: bold;

  @supports (background: color-mix(in hsl, var(--sc-main-primary) 70%, #000)) {
    --color-primary-darker: color-mix(in hsl, var(--sc-main-primary) 70%, #000);
    --text-color: var(--sc-text-secondary-1);
  }

  &.flash {
    background: #e53935;
    animation: none;
    transform: rotate(20deg) scale(1.18);
  }

  &.flash .text {
    animation: none;
  }

  .text {
    color: var(--text-color);
  }
}

.text {
  animation: rotate-reverse 1.75s infinite;
  transform: rotate(30deg);
}

.circle {
  animation: rotate 1.75s infinite;

  &.flash {
    animation: flash 1s linear;
  }
}

.animation-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}

.circle-wrapper {
  border: 4px solid var(--sc-main-primary);
  border-radius: 50%;
  padding: 1rem;
  transform: rotate(310deg);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  background: var(--sc-bg-modal);
  padding: 1.5rem;
}

.idle-modal-title {
  color: var(--sc-main-primary);
  font-size: 40px;
  line-height: 36px;
}

.idle-modal-description {
  margin-bottom: 2.5rem;
  margin-top: 1rem;
  font-size: 24px;
  line-height: 30px;
  color: var(--sc-text-primary-1);
}

.idle-modal-info {
  margin-top: 2.5rem;
  font-size: 24px;
  line-height: 1.25;
  color: var(--sc-text-primary-1);
}

@keyframes flash {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.18);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(50deg);
  }

  100% {
    transform: rotate(5deg);
  }
}

@keyframes rotate-reverse {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg);
  }
}
</style>
