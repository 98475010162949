<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { RouterView } from 'vue-router'
import { onMounted, defineAsyncComponent, ref, provide } from 'vue'
import {
  deviceManagementService,
  deviceManagementPrintService,
  useDeviceManagementStore,
  type DmPrintConfig
} from '@nsftx/seven-client-retail-vue-dm'
import { httpLogPlugin, logService } from '@nsftx/seven-client-retail-utils'
import { getAppRunUuid } from '@/utils/appRunUuidHelper'
import '../../styles/main.scss'
import STHeader from './STHeader.vue'
import STFooter from './footer/STFooter.vue'
import STNotifications from './STNotifications.vue'
import STLobbyNavigation from './lobby/STLobbyNavigation.vue'
import { useLobbyStore } from '@/stores/lobby/lobbyStore'
import STIdleWarningModal from './STIdleWarningModal.vue'
import { HOME_MAIN } from '@/utils/injections/injectionKeys'
import { usePrinterStatus } from '@/composables/usePrinterStatus/usePrinterStatus'
import printerStatusErrorParser from '@/utils/printerStatusErrorParser'

const LOG_PREFIX = '[SSBT]'
const dmStore = useDeviceManagementStore()
const { appData } = storeToRefs(dmStore)
const lobbyStore = useLobbyStore()
const { isLobbyVisible, lobbyItemContent } = storeToRefs(lobbyStore)

provide(HOME_MAIN, ref<HTMLElement>())

onMounted(async () => {
  try {
    const appRunUuid = getAppRunUuid()

    if (!appRunUuid) {
      logService.error(`${LOG_PREFIX} App run uuid is not defined`, {
        code: 'ST_APP_RUN_UUID_UNDEFINED'
      })
      return
    }

    await deviceManagementService.initDeviceManagement({ appName: '7Terminal0', appRunUuid })

    if (appData.value) {
      logService.info(`${LOG_PREFIX} App data received`, {
        appRunUuid,
        betshop: appData.value.betshop,
        device: appData.value.device,
        sevenUrl: appData.value.appConfig.data.sevenUrl,
        sevenSocketUrl: appData.value.appConfig.data.sevenSocketUrl,
        code: 'ST_DM_APP_DATA_RECEIVED'
      })
      httpLogPlugin.setAdditionalLogData({
        app_run_uuid: appRunUuid,
        tenant_id: appData.value.betshop.companyUuid,
        tenant_name: appData.value.betshop.companyName,
        device_id: appData.value.device.uuid,
        betshop_id: appData.value.betshop.uuid
      })
    }

    const printConfig: DmPrintConfig = {
      persist: { prefix: 'ssbt', storage: 'localStorage' }
    }
    await deviceManagementPrintService.init(printConfig)
  } catch (error) {
    if (printerStatusErrorParser.hasError(error)) {
      logService.error(`${LOG_PREFIX} Getting printer status on init has failed`, {
        ...printerStatusErrorParser.parseUpstream(error),
        code: 'ST_PRINT_GET_STATUS_FAILED_INIT'
      })
      return
    }
    logService.error(error)
  } finally {
    usePrinterStatus()
  }
})

const getAsyncComponents = () => {
  const STLobby = defineAsyncComponent(
    () =>
      import(
        /* webpackChunkName: "lobby" */
        '@/ui/components/lobby/STLobby.vue'
      )
  )

  return {
    STLobby
  }
}

const { STLobby } = getAsyncComponents()
</script>

<template>
  <div class="st-app">
    <STHeader>
      <template #navigation-menu>
        <STLobbyNavigation v-if="lobbyItemContent" />
      </template>
    </STHeader>
    <RouterView />
    <STFooter />
    <STLobby v-if="isLobbyVisible" />
    <STNotifications />
    <STIdleWarningModal />
  </div>
</template>

<style lang="scss">
.st-app {
  --st-app-bar-height: 64px;

  height: 100vh;
  display: grid;
  grid-template-rows: var(--st-app-bar-height) auto var(--st-app-bar-height);
}
</style>
