import { NotificationVariant } from '@nsftx/seven-components'
import { useElementScrollTop } from '@nsftx/seven-client-retail-vue-common'
import i18n from '@/plugins/translationsPlugin'
import router from '@/router'
import { useNotificationsStore } from '@/stores/notifications/notificationsStore'
import { HOME_MAIN } from '@/utils/injections/injectionKeys'
import { useLobbyStore } from '@/stores/lobby/lobbyStore'

export const useOnIdleTimerEnd = () => {
  const { scrollElementToTop } = useElementScrollTop({ injectionKey: HOME_MAIN })

  const onIdleTimerEnd = () => {
    const { t } = i18n.global
    const notificationsStore = useNotificationsStore()
    const lobbyStore = useLobbyStore()

    // Go back to landing page
    router.push({
      name: 'home',
      query: {
        lobby: lobbyStore.isLobbyVisible ? 'true' : undefined
      }
    })

    // Scroll to top after inactivity timer ends
    scrollElementToTop()

    notificationsStore.showNotification({
      messages: [t('common_landing_page_inactivity')],
      variant: NotificationVariant.Informational,
      timeout: 3000
    })
  }

  return {
    onIdleTimerEnd
  }
}
