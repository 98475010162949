import { DmPrintError } from '@nsftx/seven-client-retail-vue-dm'
import errorParser from './errorParser'

const hasError = (error: unknown) => parseCode(error) === 'DM_PRINT_STATUS_ERROR'

const parseMessage = (error: unknown) =>
  error instanceof DmPrintError
    ? error.options?.context?.printerStatus?.message || ''
    : errorParser.parseMessage(error)

const parseCode = (error: unknown) =>
  error instanceof DmPrintError ? error.code : errorParser.parseCode(error)

const parseUpstream = (error: unknown) => ({
  upstream_message: parseMessage(error),
  upstream_code: parseCode(error)
})

export default {
  hasError,
  parseUpstream
}
