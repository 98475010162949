import { logService } from '@nsftx/seven-client-retail-utils'

const LOG_PREFIX = '[appRunUuidHelper]'

const LS_APP_RUN_UUID_KEY = 'st_settings_appRunUuid'

export const storeAppRunUuid = (uuid: string) => {
  localStorage.setItem(LS_APP_RUN_UUID_KEY, uuid)
}

export const getStoredAppRunUuid = () => localStorage.getItem(LS_APP_RUN_UUID_KEY)

export const getAppRunUuid = () => {
  const urlParams = new URLSearchParams(window.location.search)

  const appRunUuid = urlParams.get('appRunUuid')

  if (appRunUuid) {
    storeAppRunUuid(appRunUuid)
    return appRunUuid
  }

  const storedUuid = getStoredAppRunUuid()

  logService.info(`${LOG_PREFIX} appRunUuid not found in the url, returning the stored one`, {
    app_run_uuid: storedUuid,
    code: 'ST_APP_RUN_UUID_NOT_FOUND_IN_URL'
  })
  return storedUuid
}
