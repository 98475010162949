<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { RouterView } from 'vue-router'
import { onMounted, defineAsyncComponent, ref, provide, onUnmounted } from 'vue'
import {
  deviceManagementService,
  deviceManagementPrintService,
  useDeviceManagementStore,
  DmPrintError,
  type DmPrintConfig
} from '@nsftx/seven-client-retail-vue-dm'
import { httpLogPlugin, logService, ScanService } from '@nsftx/seven-client-retail-utils'
import { getAppRunUuid } from '@/utils/appRunUuidHelper'
import '../../styles/main.scss'
import STHeader from './STHeader.vue'
import STFooter from './footer/STFooter.vue'
import STNotifications from './STNotifications.vue'
import STLobbyNavigation from './lobby/STLobbyNavigation.vue'
import { useLobbyStore } from '@/stores/lobby/lobbyStore'
import STIdleWarningModal from './STIdleWarningModal.vue'
import { HOME_MAIN } from '@/utils/injections/injectionKeys'
import STTicketCheck from './STTicketCheck.vue'
import STTicketCheckDetails from './STTicketCheckDetails.vue'
import STSpinner from './STSpinner.vue'
import ticketCheckService from '@/modules/ticket-check/ticketCheckService'
import { usePrinterStatus } from '@/composables/usePrinterStatus/usePrinterStatus'
import printerStatusErrorParser from '@/utils/printerStatusErrorParser'
import { initWorkingTimeService } from '@/modules/working-time/services/workingTimeService'

const LOG_PREFIX = '[SSBT]'
const scan = ScanService()
const dmStore = useDeviceManagementStore()
const { appData } = storeToRefs(dmStore)
const lobbyStore = useLobbyStore()
const { isLobbyVisible, lobbyItemContent } = storeToRefs(lobbyStore)

provide(HOME_MAIN, ref<HTMLElement>())

const addScanListener = () => {
  scan.listenForScannerEvents((res) => {
    ticketCheckService.handleScannedBarcode(res.data.code)
  })
}

onMounted(async () => {
  try {
    const appRunUuid = getAppRunUuid()

    if (!appRunUuid) {
      logService.error(`${LOG_PREFIX} App run uuid is not defined`, {
        code: 'ST_APP_RUN_UUID_UNDEFINED'
      })
      return
    }

    await deviceManagementService.initDeviceManagement({ appName: '7Terminal0', appRunUuid })
    initWorkingTimeService()

    if (appData.value) {
      httpLogPlugin.setAdditionalLogData({
        app_run_uuid: appRunUuid,
        tenant_id: appData.value.betshop.companyUuid,
        tenant_name: appData.value.betshop.companyName,
        device_id: appData.value.device.uuid,
        betshop_id: appData.value.betshop.uuid
      })

      logService.info(`${LOG_PREFIX} App data received`, {
        appRunUuid,
        code: 'ST_DM_APP_DATA_RECEIVED'
      })
    }

    const printConfig: DmPrintConfig = {
      persist: { prefix: 'ssbt', storage: 'localStorage' }
    }
    await deviceManagementPrintService.init(printConfig)

    addScanListener()
  } catch (error) {
    if (error instanceof DmPrintError && error.code === 'DM_PRINT_STATUS_ERROR') {
      logService.error(`${LOG_PREFIX} Getting printer status on init has failed`, {
        ...printerStatusErrorParser.parseUpstream(error),
        code: 'ST_PRINT_GET_STATUS_FAILED_INIT'
      })
      return
    }
    logService.error(error)
  } finally {
    usePrinterStatus()
  }
})

const getAsyncComponents = () => {
  const STLobby = defineAsyncComponent(
    () =>
      import(
        /* webpackChunkName: "lobby" */
        '@/ui/components/lobby/STLobby.vue'
      )
  )

  return {
    STLobby
  }
}

const { STLobby } = getAsyncComponents()

onUnmounted(() => {
  scan.stopListeningForScannerEvents()
})
</script>

<template>
  <div class="st-app">
    <STHeader>
      <template #navigation-menu>
        <STLobbyNavigation v-if="lobbyItemContent" />
      </template>
    </STHeader>
    <RouterView />
    <STSpinner />
    <STFooter />
    <STLobby v-if="isLobbyVisible" />
    <STNotifications />
    <STIdleWarningModal />
    <STTicketCheck />
    <STTicketCheckDetails />
  </div>
</template>

<style lang="scss">
.st-app {
  --st-app-bar-height: 64px;
  --st-content-height: calc(100vh - 2 * var(--st-app-bar-height));

  height: 100vh;
  display: grid;
  grid-template-rows: var(--st-app-bar-height) var(--st-content-height) var(--st-app-bar-height);
}
</style>
